import React from "react";
import "./Post.css";
import { Avatar } from "@mui/material";
import Me from "./images/avatar1.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import RepeatIcon from "@mui/icons-material/Repeat";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PublishIcon from "@mui/icons-material/Publish";
import Excited from "./images/excited.gif";

function Post({ displayName, username, verified, text, image, avatar }) {
  return (
    <div className="post">
      {/* This is a container around the avatar */}
      <div className="post__avatar">
        <Avatar src={Me} />
      </div>
      {/* This is a container around the body of the post */}
      <div className="post__body">
        {/* This is a container around the header of the post */}
        <div className="post__header">
        <div className="post__headerText">
          <h3>
            SupportBot{" "}
            <span className="post__headerSpecial">
              <CheckCircleIcon className="post__badge" /> @ScarletLye
            </span>
          </h3>
        </div>
        <div className="post__headerDescription">
          <p>
            Hello, and greetings to you all!
          </p>
          </div>
        </div>
        <img src={Excited} alt="Happy" />
        <div className="post__footer">
          <ChatBubbleOutlineIcon fontSize="small" />
          <RepeatIcon fontSize="small" />
          <FavoriteBorderIcon fontSize="small" />
          <PublishIcon fontSize="small" />
        </div>
      </div>
    </div>
  );
}

export default Post;
