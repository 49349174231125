import React from "react";
import "./TweetBox.css";
import { Avatar, Button } from "@mui/material";
import Me from "./images/avatar1.png";

function TweetBox() {
  return (
    <div className="tweetBox">
      <form>
        <div className="tweetBox__input">
          <Avatar src={Me} />
          <input placeholder="What's happening?" type="text" />
        </div>
        <input className="tweetBox__imageInput" placeholder="Optional: Enter image URL" />
        <Button className="tweetBox__button">Tweet</Button>
      </form>
    </div>
  );
}

export default TweetBox;
